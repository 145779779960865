@import 'global.scss';

.container {
  display: flex;
  align-items: center;
  min-height: 40px;
  gap: 8px;

  & > svg {
    min-width: 20px;
    max-width: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.text {
  color: $color-base-0;
  @include label-l2-m;
}

.textOption {
  color: $color-base-0;
  @include label-l5;

  &__bold {
    @include label-l5-m;
  }
}

.numberOption {
  color: $color-base-0;
  @include label-l2-m;
  text-wrap: nowrap;

  &__unlimited {
    @include label-l5-m;
  }
}

.priceFrom {
  color: $color-base-0;
  @include label-l5;
  text-wrap: nowrap;
}

.purple {
  .textOption {
    color: $color-base-900;
  }

  .numberOption {
    color: $color-black;
  }

  .priceFrom {
    color: $color-base-900;
  }
}
