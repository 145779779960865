@import 'variables';

@mixin only-mobile {

  @media (max-width: calc($breakpoint-tablet - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $breakpoint-tablet) and (max-width: calc($breakpoint-desktop - 1px)) {
    @content;
  }
}

@mixin tablet-sm {
  @media (min-width: $breakpoint-tablet-sm) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-max {
  @media (min-width: $breakpoint-max) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}
