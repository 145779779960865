@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  margin-top: 16px;
  padding: 8px 0;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: -8px;
    left: 0;
  }

  &__purple {
    gap: 0;
    padding: 0;

    &::before {
      background-color: $color-base-100;
    }

    @include tablet {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  &__basket {
    display: flex;
    margin-top: 0;
    gap: 8px;

    &::before {
      background-color: $color-base-400;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;

  &__purple {
    height: 36px;
  }
}

.text {
  p {
    @include label-l5;
    margin: 0;
  }
  strong {
    @include label-l5;
    font-weight: 700;
  }
}
