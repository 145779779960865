@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    url('/assets/fonts/roboto/400.woff2') format('woff2'),
    url('/assets/fonts/roboto/400.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    url('/assets/fonts/roboto/500.woff2') format('woff2'),
    url('/assets/fonts/roboto/500.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  font-weight: 500;
  font-style: normal;
  src:
    url('/assets/fonts/ttNormsPro/500.woff2') format('woff2'),
    url('/assets/fonts/ttNormsPro/500.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Caveat';
  font-weight: 400;
  font-style: normal;
  src: local('Caveat');
  src: url('/assets/fonts/Caveat.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  font-style: normal;
  src: local('Manrope');
  src: url('/assets/fonts/Manrope.ttf') format('truetype');
  font-display: swap;
}
