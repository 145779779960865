$breakpoint-mobile: 375px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-sm: 1024px;
$breakpoint-desktop: 1200px;
$breakpoint-min: $breakpoint-mobile;
$breakpoint-max: 1440px;
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

$breakpoint-mobile-tablet: 767px;
$breakpoint-tablet-desktop: 1439px;

$breakpoint-max-mobile-banner: 450px;

$font-size-h4: 24px;
$font-size-h3: 32px;
$font-size-h2: 40px;
$font-size-h1: 56px;

$corner-radius-large: 24px;
$corner-radius-small: 16px;
$corner-small: 8px;
$other-input: 12px;

$gap-mobile: 32px;
$gap-tablet: 48px;
$gap-desktop: 64px;
$gap-horizontal: 20px;

$main-page-banner-height-mobile: 273px;
$main-page-banner-height-tablet: 209px;
$main-page-banner-height-desktop: 414px;

$gradient-blue: linear-gradient(90deg, #0000be 0%, #4e00ad 100%);

$color-primary-900: #282d3c;
$color-primary-800: #071c93;
$color-primary-700: #0c2ab7;
$color-primary-600: #1139db;
$color-primary-500: #184bff;
$color-primary-400: #517bff;
$color-primary-300: #7499ff;
$color-primary-200: #a2bdff;
$color-primary-100: #d0dfff;
$color-primary-0: #ffffff;

$color-base-900: #282d3c;
$color-base-800: #414961;
$color-base-700: #515b73;
$color-base-600: #788095;
$color-base-500: #8c94a8;
$color-base-400: #bcc3d2;
$color-base-300: #e2e7f3;
$color-base-200: #ecf0f8;
$color-base-100: #f4f6fb;
$color-base-0: #ffffff;

$color-neutral-gray: #122b3d;
$color-dark-gray: #1e1e1e;

$color-accept-garden: #5cc867;
$color-error-fire: #ed584e;

$color-option-dance: #fd770d;
$color-option-ice: #70d0f4;
$color-option-lime: #b6d20d;
$color-option-flower: #4f08e8;
$color-option-berrie: #e75ca3;
$color-option-lima: #75b91f;
$color-option-aqua: #08e7dd;
$color-option-acai: #92278f;
$color-option-sun: #f8cc4d;
$color-option-drow: #2c73b4;
$color-option-steel: #adacac;
$color-option-crayola: #bcc8e5;
$color-option-pink: #fbc9f9;

$color-accordion: #d0dfff;
$color-light-grey: #e8edff;
$color-shevron-grey: #a3b1c0;

$color-magenta-0: #ec489e;
$color-magenta-100: #eb008c;
$color-magenta-200: #c20073;

$color-volna-blue: #0000be;
$color-volna-deep-blue: #03143d;

$color-black: #000000;
$color-link: #184bff;
$color-link-2: #3763ff;

$color-secondary-default: #9B9BA1;

$header-height-desktop: 84px;
$header-height-mobile: 44px;

$z-index-sidebar: 99;
$z-index-slider-controls: 199;
$z-index-sidebar-wrapper: 9999;
$z-index-sidebar-controls-hover: 201;

$z-index-header-sidebar-opened: 200;
$z-index-header-hover: 200;
$z-index-map-object-info: 5;
$z-index-menu-bottom-sheet: 201;

$max-bottom-sheet-height: 90dvh;

$z-index-search-dropdown: 100;

$z-index-footer-wrapper: 2;
$z-index-alert: 205;
$z-index-cookies: 201;
$z-index-cookies-details: 202;
