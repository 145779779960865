@import 'variables.scss';

@mixin swiper-pagination {
  display: flex;
  justify-content: center;
  height: 32px;
  align-items: center;
  gap: 8px;
  position: static;
  margin-top: 20px;
}

@mixin swiper-pagination-lock {
  display: none;
}

@mixin swiper-pagination-bullet {
  margin: 0 !important;
  width: 12px;
  height: 4px;
  border-radius: 4px;
  background-color: $color-primary-500;
  opacity: 0.6;
}

@mixin swiper-pagination-bullet-active {
  opacity: 1;
  width: 40px;
}

@mixin swiper-button-next {
  background-color: $color-base-0;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  opacity: 0.5;
  top: calc(50% + 8px);
  border: 1px solid $color-base-300;

  &:hover {
    opacity: 1;
  }

  &::after {
    color: $color-base-900;
    height: 20px;
    width: 20px;
    text-align: center;
    background-image: url('../shared/assets/svg/swiper/chevron-right.svg');
    content: '';
  }
}

@mixin swiper-button-prev {
  @include swiper-button-next;

  &::after {
    background-image: url('../shared/assets/svg/swiper/chevron-left.svg');
  }
}

@mixin swiper-button-disabled {
  display: none;
}

@mixin swiper-button-offset-right {
  opacity: 1 !important;
  right: -15px;
}

@mixin swiper-button-offset-left {
  opacity: 1 !important;
  left: -15px;
}

#__next {
  .swiper-pagination {
    @include swiper-pagination;
  }
  .swiper-pagination-lock {
    @include swiper-pagination-lock;
  }
  .swiper-pagination-bullet {
    @include swiper-pagination-bullet;
  }
  .swiper-pagination-bullet-active {
    @include swiper-pagination-bullet-active;
  }
  .swiper-button-next {
    @include swiper-button-next;
  }
  .swiper-button-prev {
    @include swiper-button-prev;
  }
  .swiper-button-disabled {
    @include swiper-button-disabled;
  }
}
