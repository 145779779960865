@import 'global.scss';

.option {
  &__homeBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    margin-top: 16px;
    @include heading-h5;
    color: $color-base-0;

    &__isHIPage {
      margin: 0;
      color: $color-primary-900;
    }
  }
}

.options {
  display: grid;
  grid-template-columns: 1fr;

  &__infoCard {
    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include desktop-max {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}
